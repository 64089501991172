import axios from "axios";
import { Message,Spin } from "view-design";

import router from "vue-router";
import { Loading } from "element-ui";

const baseUrl = "http://mmtmwms.munber.biz/api/v1/";
var lang = localStorage.getItem('lang');

if (lang == 'zh'){

    var langs = 2;
    var loadings = '加载中..'
    var request_title = '网络连接失败,请稍后重试！'
}else{

    var langs = 1;
    var loadings = 'يۈكلىنىۋاتىدۇ'
    var request_title = 'تور ئۇلنىشتا خاتالىق بار،سەل تۇرۇپ قايتا سىناڭ'

}

let loading

var token = localStorage.getItem('token');

const http = axios.create({

    baseURL:baseUrl,

    timeout:15000,

    headers:{

        "Authorization" : `Bearer ${token}`,

        "lang" : langs
    }

})

//拦截器
http.interceptors.request.use((config) => {

    loading = Loading.service({
        lock:true,
        text:loadings,
        background:'rgba(10, 10, 10, 0.8)'
    })

    return config;

},(error =>{

    return Promise.reject(error)

}));

//响应拦截器
http.interceptors.response.use((config)=>{

    if (config.status == 200 || config.status == 0){

        if (config.data.status == 200){

            Message.success(config.data.msg)

        }else{

            Message.error(config.data.msg)

        }

        loading.close()

        return Promise.resolve(config.data);


    }else if (config.status == 401){

        Message.error(config.data.msg)

        localStorage.removeItem('token')

        router.push('/')

        loading.close()

        return Promise.reject(config)


    }else{

        Message.error(config.data.msg)

        loading.close()

        return Promise.reject(config)

    }



},(error =>{
    if (error.response.status !== 401){
        Message.error({
            content:request_title,
            duration:4
        })
    }
    loading.close()
    return Promise.reject(error)

}));

export default http;